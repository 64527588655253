<template>
  <main class="relative max-w-7xl mx-auto p-2 sm:p-8">
    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'Main'
}
</script>